import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContextProvider';
// We won't use <Accordion> or <Accordion.Toggle>. We'll just use <Collapse>.
import Collapse from 'react-bootstrap/Collapse';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { convertTimeLocal } from '../../globals';

const TestHistory = () => {
    const { accessCode, isSampleTest, getApiConfig } = useContext(UserContext);
    const [fetchingHistory, setFetchingHistory] = useState(false);
    const [testHistory, setTestHistory] = useState([]);
    // Local state to toggle open/close of the collapsible panel
    const [isOpen, setIsOpen] = useState(false);

    // console.log('TestHistory rendered', {
    //     accessCode,
    //     isSampleTest,
    //     testHistoryLength: testHistory.length,
    //     isOpen
    // });

    // Fetch test history
    const fetchTestHistory = () => {
        // console.log('Fetching test history with accessCode:', accessCode);
        setFetchingHistory(true);

        fetch(getApiConfig('getSiteTestHistory'), {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ 'access-code': accessCode })
        })
            .then(response => {
                // console.log('fetchTestHistory response status:', response.status);
                return response.json();
            })
            .then(data => {
                // console.log('fetchTestHistory response data:', data);

                if (Array.isArray(data) && data.length) {
                    let onlyCompletedTests = data.filter(test => {
                        const keep = test.scoreTime !== null && test.accessCode !== accessCode;
                        // if (!keep) {
                        //     console.log(
                        //         'Filtering out test with scoreTime:',
                        //         test.scoreTime,
                        //         'accessCode:',
                        //         test.accessCode,
                        //         'Current code:',
                        //         accessCode
                        //     );
                        // }
                        return keep;
                    });
                    // console.log('onlyCompletedTests:', onlyCompletedTests);

                    let reverseChronologicalTests = onlyCompletedTests.sort(
                        (a, b) => Date.parse(b.scoreTime) - Date.parse(a.scoreTime)
                    );
                    // console.log('reverseChronologicalTests:', reverseChronologicalTests);

                    setTestHistory(reverseChronologicalTests);
                } else {
                    console.log('No data or empty array returned; setting testHistory to []');
                    setTestHistory([]);
                }
            })
            .catch(error => {
                console.error('Error getting test history:', error);
            })
            .finally(() => {
                setFetchingHistory(false);
            });
    };

    // Fetch test history when accessCode changes
    useEffect(() => {
        if (accessCode) {
            console.log('useEffect triggered by accessCode change; calling fetchTestHistory');
            fetchTestHistory();
        } else {
            console.log('No accessCode provided, skipping fetchTestHistory');
        }
    }, [accessCode]);

    return (
        <Card className="mb-3">
            {/*
        Card.Header is clickable. Toggling isOpen changes
        whether we show or hide the card body in <Collapse>.
      */}
            <Card.Header
                className="point d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                <span className="ml-2">Previous Test History</span>
            </Card.Header>

            {/*
        Use Collapse in={isOpen} to show/hide the Card.Body.
        No AccordionContext needed.
      */}
            <Collapse in={isOpen}>
                <Card.Body>
                    {isSampleTest ? (
                        <p>If this were a real test, your previous scores would be displayed here.</p>
                    ) : fetchingHistory ? (
                        <div style={{ textAlign: 'center' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading Test History...</span>
                            </Spinner>
                        </div>
                    ) : testHistory.length > 0 ? (
                        <Table>
                            <thead>
                            <tr>
                                <td>Date Test Completed</td>
                                <td>Test Type</td>
                                <td>Test Status</td>
                                <td>View Results</td>
                            </tr>
                            </thead>
                            <tbody>
                            {testHistory.map((test, i) => (
                                <tr key={i}>
                                    <td>{convertTimeLocal(test.scoreTime)}</td>
                                    <td>
                                        {test.examType}
                                        {test.roleDisplayName && test.roleDisplayName.trim() !== ''
                                            ? ` (${test.roleDisplayName})`
                                            : ''}
                                    </td>
                                    <td>{test.status}</td>
                                    <td>
                                        <Link
                                            to={{
                                                pathname: '/viewhistoricalresults',
                                                hist_access_code: test.accessCode,
                                                hist_role: test.roleDisplayName
                                            }}
                                        >
                                            Results
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    ) : (
                        'No Previous Test History'
                    )}
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default TestHistory;
