import React, { useContext, useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import DeceptionCard from './tools/DeceptionCard';
import TestHistory from './tools/TestHistory';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserContextProvider';
import { failureCheckObject, handleReturnToLeapFrog } from '../globals';
import useDisableBackButton from './hooks/useDisableBackButton';
import { convertTimeLocal } from '../globals';

const ViewHistoricalResults = (props) => {
  const {
    getApiConfig,
    isSampleTest,
    exitSampleTest,
    removeSampleTestDeadline,
    leapFrogDashboardUrl,
    testFacilityName,
    leapFrogHospitalId,
    ttGroup
  } = useContext(UserContext);

  // scoring state
  const [historicalAccessCode, setHistoricalAccessCode] = useState('');
  const [scoreData, setScoreData] = useState({});
  const [medRecScore, setMedRecScore] = useState('');
  const [sortedScores, setSortedScores] = useState([]);
  const [fatalAnalysis, setFatalAnalysis] = useState([]);
  const [alertAnalysis, setAlertAnalysis] = useState([]);
  const [totalScore, setTotalScore] = useState(null);
  const [totalScoreNumber, setTotalScoreNumber] = useState(null);
  const [scoreMessage, setScoreMessage] = useState('');
  const [deceptionFailed, setDeceptionFailed] = useState(false);
  const [completionTime, setCompletionTime] = useState('');
  const [examType, setExamType] = useState('');
  // TODO: remove the use of missing categories list. Only the categories supplied by the Server should be displayed.
  const [categories] = useState([]);
  // const [categories] = useState([ // setting category names in state plays nicer with useEffect
  //   'Drug Age',
  //   'Drug Allergy',
  //   'Drug Dose (Daily)',
  //   'Drug Dose (Single)',
  //   'Drug Diagnosis',
  //   'Drug Laboratory',
  //   'Drug Monitoring',
  //   'Drug Route',
  //   'Excessive Alerts',
  //   'Inappropriate Medication Combinations',
  // ])

  const history = useHistory();

  // reassign props
  const hist_access_code = props.location.hist_access_code;
  const hist_role = props.location.hist_role;

  useDisableBackButton(); // Disable the browser's back button

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch scoring data by Access Code
  useEffect(() => {
    if (hist_access_code !== historicalAccessCode) {
      setDeceptionFailed(false); // needs to be reset or the display gets stuck on this result
      // console.log("historicalAccessCode changed");
      // console.log("historicalAccessCode>"+historicalAccessCode);
      // console.log("hist_access_code>"+hist_access_code);
      setHistoricalAccessCode(hist_access_code);

      fetch(getApiConfig('getScoreByAccessCode'), {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          'access-code': hist_access_code
        })
      })
          .then((response) => {
            // Quietly handle non-OK status
            if (!response.ok) {
              console.warn(
                  'Received non-OK status from getScoreByAccessCode:',
                  response.status,
                  response.statusText
              );
              return null; // no JSON parsing for error responses
            }
            return response.json();
          })
          .then((data) => {
            if (!data) {
              // Means the response was not OK or no data returned
              return;
            }
            // console.log('score data:', data)
            setScoreData(data);
            // console.log('total score number', data.total_score_number)
            setTotalScoreNumber(parseInt(data.total_score_number));
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }
  }, [getApiConfig, historicalAccessCode, hist_access_code]);

  // fetch test history to get completion time & examType
  useEffect(() => {
    if (completionTime === '' || examType === '') {
      console.log(
          'Fetching test history for results page ' + getApiConfig('getSiteTestHistory')
      );

      fetch(getApiConfig('getSiteTestHistory'), {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ 'access-code': historicalAccessCode })
      })
          .then((response) => {
            // Quietly handle non-OK status
            if (!response.ok) {
              console.warn(
                  'Received non-OK status from getSiteTestHistory:',
                  response.status,
                  response.statusText
              );
              return null;
            }
            return response.json();
          })
          .then((data) => {
            if (!data) {
              // Means the response was not OK or no data returned
              return;
            }
            // console.log("***test history in historical results page:", data);
            // console.log('current test access code', accessCode);
            if (data.length) {
              // only keep the data from the current test
              let onlyCurrentTest = data.filter(
                  (test) => test.accessCode === historicalAccessCode
              );
              setCompletionTime(onlyCurrentTest[0].scoreTime);
              setExamType(onlyCurrentTest[0].examType.replace(/Adult/, ''));
            } else {
              setCompletionTime('unknown');
            }
          })
          .catch((error) => {
            console.error('Error getting test history:', error);
          });
    }
  }, [historicalAccessCode, getApiConfig, completionTime, examType]);

  // fetch analysis
  useEffect(() => {
    // get analysis by sitecode
    fetch(getApiConfig('getAmbulatoryAnalysisByAccessCode'), {
      // adding 'excessive' as a key
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        'access-code': hist_access_code
      })
    })
        .then((response) => {
          // Quietly handle non-OK status
          if (!response.ok) {
            console.warn(
                'Received non-OK status from getAmbulatoryAnalysisByAccessCode:',
                response.status,
                response.statusText
            );
            return null;
          }
          return response.json();
        })
        .then((data) => {
          if (!data) {
            // Means the response was not OK or no data returned
            return;
          }
          // console.log("Success grabbing ANALYSIS data:", data);
          if (failureCheckObject(data)) {
            setFatalAnalysis(data['fatal']);
            // changing this from 'nuisance' to 'excessive'
            setAlertAnalysis(data['excessive'] ? data['excessive'] : []);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }, [hist_access_code, getApiConfig]);

  // arrange score percentages low to high, and add in any missing categories
  useEffect(() => {
    let sortable = [];
    // create pairs and parse the percentage string as a float
    for (var entry in scoreData.category_percentage) {
      // Check if the cat was insufficient and got a message instead of a percentage
      if (
          scoreData.category_percentage[entry] ===
          'Insufficient responses to evaluate performance in this category'
      ) {
        sortable.unshift([entry, scoreData.category_percentage[entry]]);
      } else {
        sortable.push([entry, parseFloat(scoreData.category_percentage[entry])]);
      }
    }

    // sort the pairs based on the second value of each pair -- this is deprecated, now sorting alphabetically - see below
    // sortable.sort(function(a, b) { return a[1] - b[1] });

    // cast the percentage float back to a string if it was scored
    sortable.forEach((entry) => {
      if (
          entry[1] !== 'Insufficient responses to evaluate performance in this category'
      ) {
        entry[1] = `${entry[1]}%`;
      }
    });
    setTotalScore(scoreData.total_score_percentage);

    let mRScoreArray = sortable.filter((el) => el[0] === 'Medication Reconciliation');
    if (mRScoreArray.length) {
      // if Med Rec exists, filter out the Med Rec score, set it in state
      sortable = sortable.filter((el) => el[0] !== 'Medication Reconciliation');
      setMedRecScore(mRScoreArray[0][1]);
    }

    // Create an array of categories from `sortable` to compare to `categories` in state, add in any missing categories
    let sortedCategories = [];
    sortable.forEach((el) => sortedCategories.push(el[0]));
    categories.forEach((cat) => {
      if (!sortedCategories.includes(cat)) {
        sortable.unshift([
          cat,
          'Insufficient responses to evaluate performance in this category'
        ]);
      }
    });
    // console.log('sortable', sortable)

    // sort the score categories alphabetically by category name
    const alphabeticallySortedResults = sortable.sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    });
    // console.log('alpha sorted:', alphabeticallySortedResults)
    setSortedScores(alphabeticallySortedResults);
  }, [scoreData, categories]);

  // set the scoring message
  useEffect(() => {
    if (totalScoreNumber !== null) {
      /*
        SCORING MESSAGES FOR DECEPTION/TIMEOUT
        Total score of '1' is 'Insufficient Evaluation' ... meaning 20 or more -1 answers
        Total score of '0' is Deception failed because more than 2 deceptions failed
        Total score of '-1' is timeout of test occured.
      */
      switch (totalScoreNumber) {
        case 5:
          setScoreMessage(
              'Full Demonstration of National Safety Standard for Decision Support'
          );
          break;
        case 4:
          setScoreMessage(
              'Substantial Demonstration of National Safety Standard for Decision Support'
          );
          break;
        case 3:
          setScoreMessage(
              'Some Demonstration of National Safety Standard for Decision Support'
          );
          break;
        case 2:
          setScoreMessage('Completed the Evaluation');
          break;
        case 1:
          setScoreMessage('Insufficient Evaluation');
          break;
        case 0:
          setDeceptionFailed(true); // need to use + here to fire both functions?
          setScoreMessage('Potentially Inaccurate Test Results');
          break;
        case -1:
          setScoreMessage('Timed Out of Test');
          break;
        default:
          setScoreMessage('');
      }
    }
  }, [totalScoreNumber]);

  const handleAdvance = () => {
    // redirect to dashboard
    history.push('/dashboard');
  };

  const buttonStyle = {
    marginTop: '1em',
    fontWeight: '300'
  };

  return (
      <Container className="mt-3 mb-5 pt-3 pb-3" style={{ fontWeight: '300' }}>
        <Row>
          <Col sm={12}>
            <h1>View Results</h1>
            <h2 className="text-center">
              Adult {examType} Test
              {hist_role && hist_role.trim() !== '' ? ` (${hist_role})` : ''}
            </h2>
            <h2 className="text-center">
              {testFacilityName} {leapFrogHospitalId}
            </h2>
            <p className="text-center">
              <em>
                Test date and time:{' '}
                {isSampleTest
                    ? 'If this were a real test, the date and time that you completed the test would be displayed here.'
                    : convertTimeLocal(completionTime)}
              </em>
            </p>
            {Object.keys(scoreData).length === 0 && failureCheckObject(scoreData) ? (
                <div style={{ textAlign: 'center' }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Scoring test...</span>
                  </Spinner>
                </div>
            ) : deceptionFailed ? (
                <DeceptionCard ttGroup={ttGroup} />
            ) : (
                <Table responsive hover>
                  <thead>
                  <tr>
                    <td>
                      <strong>Category</strong>
                    </td>
                    <td className="text-center">
                      <strong>Score</strong>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  {sortedScores.map((entry) => {
                    return (
                        <tr key={entry[0]}>
                          <td>{entry[0]}</td>
                          <td className="text-center">{entry[1]}</td>
                        </tr>
                    );
                  })}
                  {medRecScore && (
                      <tr>
                        <td>Medication Reconciliation*</td>
                        <td className="text-center">{medRecScore}</td>
                      </tr>
                  )}
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td className="text-center">{totalScore}</td>
                  </tr>
                  </tbody>
                </Table>
            )}
            {medRecScore && (
                <p className="text-center">
                  <em>
                    * - Medication Reconciliation score does not change the total score.
                  </em>
                </p>
            )}
            {/* {scoreMessage !== '' ? <p className="text-center"><em>{scoreMessage}</em></p> : ''} */}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {isSampleTest || deceptionFailed ? (
                ''
            ) : (
                <>
                  <Card className="mt-1">
                    <Card.Header>Fatal Orders Analysis</Card.Header>
                    <Card.Body style={{ backgroundColor: 'white' }}>
                      <p>The following Fatal Orders were missed:</p>
                      <ul>
                        {fatalAnalysis.length === 0 ? (
                            <li>None</li>
                        ) : (
                            fatalAnalysis.map((order) => (
                                <li key={order}>
                                  <strong>{order}</strong>
                                </li>
                            ))
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                  <Card className="mt-1">
                    <Card.Header>Excessive Alerts Analysis</Card.Header>
                    <Card.Body style={{ backgroundColor: 'white' }}>
                      <p>The following Excessive Alerts orders were missed:</p>
                      <ul>
                        {alertAnalysis.length === 0 ? (
                            <li>None</li>
                        ) : (
                            alertAnalysis.map((order) => (
                                <li key={order}>
                                  <strong>{order}</strong>
                                </li>
                            ))
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                </>
            )}
            {deceptionFailed || isSampleTest ? (
                ''
            ) : (
                <Card className="mt-1">
                  <Card.Header>Overall Score</Card.Header>
                  <Card.Body style={{ backgroundColor: 'white' }}>
                    <p>{scoreMessage !== '' ? scoreMessage : ''}</p>
                  </Card.Body>
                </Card>
            )}
            {
              <div className="mt-1">
                <TestHistory />
              </div>
            }
            <div style={{ textAlign: 'center' }}>
              <Button
                  style={buttonStyle}
                  onClick={handleAdvance}
                  className="px-3"
                  variant="info"
              >
                Dashboard
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
  );
};

export default ViewHistoricalResults;
