import Card from 'react-bootstrap/Card';

const DeceptionCard = ({ttGroup}) => {

    const LFhelpdeskLink = 'https://leapfroghelpdesk.zendesk.com/hc/en-us'

    if (ttGroup === "LF") {
      return (
        <Card className="mt-3">
          <Card.Header>
          Potentially Inaccurate Test Results:
          </Card.Header>
          <Card.Body style={{ backgroundColor: "white" }}>
          <p><strong>This hospital submitted responses that included potentially inaccurate results. The hospital is eligible to retake the test in 120 days. Please contact the <a href={LFhelpdeskLink} target="_blank" rel="noopener noreferrer">Leapfrog Help Desk</a> for further assistance.</strong></p>
          </Card.Body>
        </Card>
      )
    }

    return (
      <Card className="mt-3">
        <Card.Header>
        Potentially Inaccurate Test Results (Incomplete Evaluation):
        </Card.Header>
        <Card.Body style={{ backgroundColor: "white" }}>
        <p><strong>This hospital submitted responses that included potentially inaccurate results. The hospital is eligible to retake. Please contact <a href="mailto: Helpdesk_EHRSafetyEvalTool@utah.edu">Helpdesk_EHRSafetyEvalTool@utah.edu</a> for further assistance.</strong></p>
        </Card.Body>
      </Card>
    )
}

export default DeceptionCard