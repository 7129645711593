import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContextProvider";
import dayjs from 'dayjs'
import Reminder from '../pages/tools/Reminder'
import useDisableBackButton from "./hooks/useDisableBackButton";
import useSetCurrentStep from './hooks/useSetCurrentStep'


const SubmitAffirmation = () => {
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  // const [affirmed, setAffirmed] = useState(false);
  const [posting, setPosting] = useState(false)
  const [feedback, setFeedback] = useState('')
  
  const { accessCode, getApiConfig, setCurrentStep, ttGroup } = useContext(UserContext);

  const history = useHistory();

  useDisableBackButton()  // Disable the browser's back button
  useSetCurrentStep("ATTESTATION", 6)
   
  //scroll to top of page on load
  useEffect(() => { window.scrollTo(0, 0) }, [])

  const handleSetFirstName = (e) => {
    e.preventDefault()
    setFirstName(e.target.value)
  }

  const handleSetLastName = (e) => {
    e.preventDefault()
    setLastName(e.target.value)
  }

  const handleSetTitle = (e) => {
    e.preventDefault()
    setTitle(e.target.value)
  }

  const postAffirmation = () => {
    const postTimeout = setTimeout(() => {
      // set current step to next step
      setCurrentStep("/viewresults");
      // redirect to next step
      history.push("/viewresults");
      setPosting(false)
      setFeedback('')
    }, 6200)
    return () => clearTimeout(postTimeout)
  }
    

  const handleAdvance = () => {
    setPosting(true)
    setFeedback('Affirming your results may take a moment...')
    fetch(getApiConfig("setAttestationComplete"), {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        "access-code": accessCode,
        "first-name": firstName,
        "last-name": lastName,
        "title": title
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Attestation Posted: ", data.response);
        // run timeout -> setCurrentStep, history push function here
        postAffirmation()
      })
      .catch((error) => {
        console.error("Error:", error);
        // stop spinner and show feedback
        return setPosting(false) + setFeedback('There was an error affirming your results, please try again.')
      });
  };

  const buttonStyle = {
    marginTop: "1em",
    fontWeight: "300",
  };

  return (
    <Container className="mt-3 mb-5 pt-3 pb-3" style={{ fontWeight: "300" }}>
      <Row>
        <Col sm={12}>
          <h1 tabIndex="0">Step 6 of 7: Submit Affirmation</h1>
          <hr />
          <Reminder />
          <p style={{color: 'red'}}>
            <strong>
              Complete and submit the affirmation so that your responses can be scored. If you close the browser before submitting the affirmation you will not be able to return to the test for 120 days and your hospital will receive a score of "Incomplete Evaluation."
            </strong>
          </p>
          <strong>Statement of Accuracy</strong>
          {ttGroup === 'LF' ? 
          <div>
          <p>
            As the hospital CEO or as an employee of the hospital to whom the
            hospital CEO has delegated responsibility, I and the hospital have
            reviewed the information the hospital is submitting pertaining to
            the CPOE Evaluation Tool Section, and I hereby certify that this
            information is true, accurate, and reflects the current, normal
            operating circumstances at our hospital. I and the hospital have
            made a good faith effort to complete the test consistent with the
            instructions provided in the CPOE Evaluation Tool (the "Tool") and
            to report accurately the information presented by our hospital's
            CPOE system upon prescriber order-entry. I am authorized to make
            this certification on behalf of our hospital.
          </p>

          <p>
            The hospital and I understand that The Leapfrog Group, its members,
            the public and entities and persons who contract with Leapfrog are
            relying on the truth and accuracy of this information. The hospital
            and I also understand that The Leapfrog Group will make overall
            scores and aggregate reports of this information public through the
            survey results public reporting website, The Leapfrog Group's
            Hospital Safety Grade, and/or other Leapfrog Group products and
            services. This information and/or analyses and all intellectual
            property rights therein shall be and remain the sole and exclusive
            property of The Leapfrog Group. This information provided by the
            hospital does not infringe any third party's intellectual property
            rights. The hospital and I acknowledge that The Leapfrog Group may
            use this information in a commercial manner for profit. The hospital
            shall be liable for and shall hold harmless and indemnify The
            Leapfrog Group, The Brigham and Women's Hospital, Inc. and The
            University of Utah from any and all damages, demands, costs, or
            causes of action resulting from any inaccuracies in the information
            or any misrepresentations in this Affirmation of Accuracy. The
            Leapfrog Group and its members and entities and persons who contract
            with Leapfrog reserve the right to omit or disclaim information that
            is not current, accurate or truthful.
          </p>

          <p>
            <em><small>
              The hospital and I understand and agree that the Tool is provided
              by The Brigham and Women's Hospital, Inc., The University of Utah
              and The Leapfrog Group, and other entities with whom The Leapfrog
              Group contracts to make the Tool available on an as-is basis
              without any warranties or representations that the test thoroughly
              tests, audits, or validates a hospital's CPOE or other clinical
              decision support system(s).</small>
            </em>
          </p>

          <p>
            <em><small>
              The hospital and I understand that hospitals do not obtain any
              rights in the Tool by virtue of conducting, administering, or
              participating in the Leapfrog Hospital Survey (the
              "Survey"). Hospitals are permitted to use the Tool solely for
              purposes of such Survey participation and only within their
              organization. Hospitals must obtain explicit written permission
              from The Brigham and Women's Hospital, Inc., The University of
              Utah, and The Leapfrog Group to use the Tool for any other purpose
              or to share the Tool with other entities or with persons other
              than their direct employees involved in the Survey. For clarity,
              the Tool includes software and content, including but not limited
              to patient descriptions and medication order scenarios, and the
              restrictions and requirements stated above apply to the entire
              Tool and any of its components.</small> 
            </em>
          </p>
          </div>
          : 
          <div>
          <p>
            As the VA Medical Center (VAMC) representative or as an employee to whom the
            VAMC representative has delegated responsibility, I and the VAMC have reviewed the
            information the VAMC is submitting regarding the CPOE/EHR Evaluation Tool Section. I
            hereby certify that this information is true, accurate, and reflects the current, normal
            operating circumstances at our VAMC.
          </p>
          </div>}
          <Form>
                <Row><Col><p style={{ textAlign: "center" }}><strong>Affirmed and Submitted by: </strong></p></Col></Row>
              <Row>
                <Form.Group as={Col} controlId="formGridFirstName">
                  <Form.Label>First name</Form.Label>
                  <Form.Control type="text" onChange={handleSetFirstName} />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control type="text" onChange={handleSetLastName} />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" onChange={handleSetTitle} />
                </Form.Group>
              </Row>
              <Row>
                <Col>
                <div style={{ textAlign: "center", marginBottom: '10px' }}>on {dayjs().format('MM/DD/YYYY')}</div>
                {/* <p style={{ textAlign: "center"}}><em>*Must affirm before score is viewable otherwise it is an incomplete evaluation</em></p> */}
                {/* LF requested to remove on 1/5/22 */}
                </Col>
              </Row>
          </Form>
          <div style={{ textAlign: "center" }}>
            { posting ? 
            <Button variant="info" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" "}Affirming Test Responses...
            </Button>
            : (firstName !== '') && (lastName !== '') && (title !== '') ? (
              <Button
                style={buttonStyle}
                onClick={handleAdvance}
                className="px-3"
                variant="info"
              >
                Affirm Test Responses and Proceed to Scored Results
              </Button>
            ) : (
              <Button
                style={{ marginTop: "1em", fontWeight: "300", opacity: 0.4 }}
                variant="info"
                className="px-3"
              >
                Affirm Test Responses and Proceed to Scored Results
              </Button>
            )}
          </div>
          <div className="text-center mt-3"><p><em>{feedback !== '' ? feedback : ""}</em></p></div>
        </Col>
      </Row>
    </Container>
  );
};

export default SubmitAffirmation;
