import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { UserContext } from './context/UserContextProvider';
import Dashboard from './pages/Dashboard';
import SampleDashboard from './pages/SampleDashboard';
import InstructionsPDF from './pages/InstructionsPDF';
import PatientsPDF from './pages/PatientsPDF';
import EnterPatients from './pages/EnterPatients';
import TestPDF from './pages/TestPDF';
import EnterOrders from './pages/EnterOrders';
import RecordResponses from './pages/RecordResponses';
import SubmitAffirmation from './pages/SubmitAffirmation';
import ViewResults from './pages/ViewResults';
import Login from './pages/Login';
import Header from './Header';
import Footer from './Footer';
import TimeOutModal from './pages/tools/TimeOutModal';
import ViewHistoricalResults from "./pages/ViewHistoricalResults";
// import HelpWiki from './pages/HelpWiki';

function useRouteChangeLogger() {
    const location = useLocation();

    useEffect(() => {
        console.log(`Route changed to: ${location.pathname}`);
    }, [location]);
}

function App() {
    const contextData = useContext(UserContext);

    function PrivateRoute({ component: Component, ...rest }) {
        console.log(`PrivateRoute called for path: ${rest.path}`);

        return (
            <Route
                {...rest}
                render={props => {
                    console.log(`Rendering component for path: ${rest.path}`);
                    console.log(`Is user logged in? ${contextData.isLoggedIn}`);
                    console.log(`User's current step: ${contextData.currentStep}`);

                    if (contextData.isLoggedIn) {
                        console.log(`User is logged in, rendering component for ${rest.path}`);
                        return <Component {...props} />
                    } else {
                        console.log(`User is not logged in, redirecting to login`);
                        return <Redirect to={{
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        }}
                        />
                    }
                }}
            />
        );
    }

    return (
        <div className="App">
            <Router>
                <Header />
                {/* Route change logger inside the Router */}
                <useRouteChangeLogger />
                <Switch>
                    {/* <Route path="/login" component={Login}/> */}
                    <PrivateRoute path="/instructionspdf" component={InstructionsPDF} />
                    <PrivateRoute path="/patientspdf" component={PatientsPDF} />
                    <PrivateRoute path="/enterpatients" component={EnterPatients} />
                    <PrivateRoute path="/testpdf" component={TestPDF} />
                    <PrivateRoute path="/enterorders" component={EnterOrders} />
                    <PrivateRoute path="/recordresponses" component={RecordResponses} />
                    <PrivateRoute path="/submitaffirmation" component={SubmitAffirmation} />
                    <PrivateRoute path="/viewresults" component={ViewResults} />
                    <PrivateRoute
                        path="/viewhistoricalresults"
                        component={(props) => (
                            <ViewHistoricalResults
                                // Use 'key' so React unmounts/remounts the component if this changes:
                                key={props.location?.hist_access_code}
                                {...props}
                            />
                        )}
                    />
                    <PrivateRoute path="/sampledashboard" component={SampleDashboard} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    {/* <PrivateRoute path="/helpwiki" component={HelpWiki} /> */}
                    <Route path="/">
                        {contextData.isLoggedIn ? <Dashboard /> : <Login />}
                    </Route>
                </Switch>
                <TimeOutModal />
                {contextData.isLoggedIn ? <Footer /> : ''}
            </Router>
        </div>
    );
}

export default App;
