import React, { useContext } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { UserContext } from "../context/UserContextProvider";
import useDisableBackButton from "./hooks/useDisableBackButton";

const SampleDashboard = () => {

  // Business rules: once you start a sample test, you must complete it
  //  - 'exit sample test' rearranges original access code and pushes user to main Dashboard
  const { setSampleTest, setCurrentStep, setSampleTestDeadline, isLeapFrogTest } = useContext(UserContext)

  useDisableBackButton()  // Disable the browser's back button 
  
  const handleSampleAndCurrentStep = () => {
    setSampleTest()
    setCurrentStep("/patientspdf")
    // create the clientside 'sample deadline'
    setSampleTestDeadline()
  }

    return (
      <Container className="mt-5" style={{ fontWeight: '300' }}>
        <Card className="mb-3">
          <Card.Header className="font-weight-bold">
            Welcome to the Sample Test
          </Card.Header>
          <Card.Body>
          <p>The Sample Test allows your team, including the prescriber, to familiarize themselves
            with the Adult Inpatient Test process. This includes practicing time management,
            following each step, understanding Order Checking Categories, verifying browser
            compatibility, and testing lab and ADT links.
          </p>
          <p><strong>Key Features of the Sample Test:</strong></p>
          <ul>
            <li><strong>Unscored Practice:</strong> The Sample Test is not scored and does not predict your
            hospital's performance on the actual Adult Inpatient Test.</li>
            <li><strong>Limited Scope:</strong> It includes two Test Patients and eight Test Orders.</li>
            <li><strong>Time Simulation:</strong> It follows the same time limits as the Adult Inpatient Test (3
                hours for Steps 1 - 2 and 3 hours for Steps 3 - 6) but will not automatically time
                out if the time limits are exceeded.</li>
            <li><strong>Estimated Time:</strong> Plan for approximately 60 minutes to complete it.</li>
            <li><strong>Restarting:</strong> Closing the browser will restart the test.</li>
            <li><strong>Repeatable Practice:</strong> You can retake the Sample Test as many times as
            needed, but the Test Patients and Test Orders will remain the same.</li>
          </ul>

          <p>Use the
            {' '}
            <a href="https://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials" target="_blank" rel="noreferrer">Adult Inpatient Test instructions</a>
            {' '}
            to complete Steps 1 - 6 of the Sample Test.
            Afterward, review your team's experience to determine readiness for the Adult Inpatient
            Test. Address any technical issues encountered during the Sample Test before starting
            the Adult Inpatient Test.</p>
          </Card.Body>
        </Card>
        {/* {isLeapFrogTest ? <InstructionsTableLF2023 /> : <InstructionsTable isLeapFrogTest={isLeapFrogTest} />} */}
          <div style={{textAlign: 'right'}}>
            <LinkContainer to="/dashboard">
              <Button variant="warning">Exit Sample Test</Button>
            </LinkContainer>
            {" "}
            <LinkContainer to="/patientspdf">
              <Button onClick={handleSampleAndCurrentStep} variant='info'>Begin Sample Test</Button>
            </LinkContainer>
          </div>
      </Container>
    );
  
}

export default SampleDashboard;